
export default {
  props: {
    page: {
      type: Object
    }
  },
  data: () => ({
    items: [
      { text: 'Gain de temps et tranquilité d\'esprit', icon: 'check-circle'},
      { text: 'Rapidité et simplicité', icon: 'check-circle'},
      { text: 'Equipe expérimentée, à taille humaine', icon: 'check-circle'},
      { text: 'Process sécurisé et financièrement sûr', icon: 'check-circle'},
    ]
  })
}
